<template>
    <section class="content">
        <div class="card">
            <div class="card-body" style="align-self: center; display: flex;">
                <span style="align-self: center; font-size: initial;">Bulan</span>&nbsp;&nbsp;
                <div class="input-group">
                  <datepicker
                      placeholder="Bulan"
                      class="datep"
                      v-model="f_bulan"
                      v-on:change="search"
                      :options="{format:'yyyy-mm', startView: 'months', minViewMode: 'months', autoclose: true}" 
                      :autoclose=true
                      required
                    />
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  </div>
                </div>
                <button style="margin-left: 8px;" type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
            </div>
        </div>
        <table class="table table-hover" ref="tableuser" style="width:100%;text-align: center;">
            <thead>
                <tr>
                    <th>Kelas</th>
                    <th>Senin</th>
                    <th>Selasa</th>
                    <th>Rabu</th>
                    <th>Kamis</th>
                    <th>Jumat</th>
                    <th>Sabtu</th>
                    <th>Minggu</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <br>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Code</label>
                                <input type="hidden" name="row_id" v-model="form.id"/>
                                <input id="code" class="form-control code" v-model="form.code" type="text" required="required" name="code" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tentor</label>
                                <v-select :options="optTentor" v-model="form.tentor_id" :reduce="opt => opt.id" @input="getMapel" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Mapel</label>
                                <v-select :options="optMapel" v-model="form.mapel_id" :reduce="opt => opt.id" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Kelas</label>
                                <v-select :options="optKelas" v-model="form.kelas_id" :reduce="opt => opt.id" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tanggal</label>
                                <datepicker v-model="form.date" />
                            </div>
                            <div class="row" style="padding: 7.5px;">
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Mulai</label>
                                    <timepicker  v-model="form.time_start" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Selesai</label>
                                    <timepicker  v-model="form.time_end" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import datepicker from '@/components/Datepicker';
import timepicker from '@/components/Timepicker';
import Swal from "sweetalert2";

export default {
    name: 'DataGuru',
    components: {
        vSelect,
        datepicker,
        timepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            formTitle: 'Tambah',
            optMapel: [],
            optKelas: [],
            optTentor: [],
            form: {
                id: '',
                code: '',
                mapel_id: '',
                kelas_id: '',
                tentor_id: '',
                date: '',
                time_start: '',
                time_end: ''
            },
            f_bulan : ''
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        authFetch('/akademik/jadwal_kelas/mapel')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optMapel = js.data;
            })
        authFetch('/akademik/jadwal_kelas/kelas')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optKelas = js.data;
            })
        authFetch('/akademik/jadwal_kelas/tentor')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optTentor = js.data;
            })
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-edit')) {
                var self = this;
                const v = this.$refs;
                authFetch('/akademik/jadwal_kelas/'+e.target.getAttribute('dataset'))
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    console.log(js)
                    self.form = js;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Info Jadwal Kelas';
                    $(v.formDialog).modal('show');
                })
            }
        },
        getMapel(value) {
            console.log(value)
            authFetch('/akademik/jadwal_kelas/mapel/'+value)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.form.mapel_id = '';
                this.optMapel = js.data;
            })
        },
        submitForm: function(ev) {
            var self = this;
            const e = this.$refs;

            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/akademik/jadwal_kelas';
            if (this.method == 'PUT') urlSubmit = '/akademik/jadwal_kelas/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        },
        deleteItem: function(id) {
            const e = this.$refs;
            Swal.fire({
                title: "Hapus data?",
                icon: "question",
                denyButtonText: '<i class="fa fa-times"></i> Hapus',
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
              }).then((result) => {
                if (result.isDenied) {
                  authFetch("/akademik/jadwal_kelas/" + id, {
                    method: "DELETE",
                    body: "id=" + id,
                  })
                    .then((res) => {
                      return res.json();
                    })
                    .then((js) => {
                      $(e.formDialog).modal('hide');
                      this.table.api().ajax.reload();
                    });
                }
              });
        },
        search: function() {
            let self = this;
            this.table.api().ajax.reload();
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": 'List Jadwal Kelas<br/><span style="font-size:x-small;color:red">*) Klik pada badge kelas untuk melakukan ubah atau hapus.</span>',
            "roles": this.$route.params.roles,
            "ajax": "/akademik/jadwal_kelas",
            "columns": [
                { "data": "kelas" },
                { "data": "senin", sortable: false },
                { "data": "selasa", sortable: false },
                { "data": "rabu", sortable: false },
                { "data": "kamis", sortable: false },
                { "data": "jumat", sortable: false },
                { "data": "sabtu", sortable: false },
                { "data": "minggu", sortable: false },
            ],
            filterBy: [0],
            scrollX: true,
            "rowCallback": function(row, data) {
                // $('td:eq(1)', row).html('-');
            },
            paramData: function ( d ) {
                d.month = self.f_bulan;
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    return false
                } else if (evt.role == 'update' && evt.data) {
                    return false
                } else if (evt.role == 'delete' && evt.data) {
                    return false
                }

            },
            dom: "<'row'<'col-sm-12 col-md-6 mb-2'B>><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
                {
                    extend: 'excel',
                    text: 'XLSX',
                    className: 'btn btn-success',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    title: function(){
                        return 'Jadwal Kelas';
                    },
                    filename: function(){
                        return 'Jadwal Kelas';
                    },
                },
                {
                    extend: 'print',
                    text: 'Print',
                    className: 'btn btn-primary',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    title: function(){
                        return 'Jadwal Kelas';
                    },
                    filename: function(){
                        return 'Jadwal Kelas';
                    },
                },
                {
                    extend: 'pdf',
                    text: 'PDF',
                    className: 'btn btn-info',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    title: function(){
                        return 'Jadwal Kelas';
                    },
                    filename: function(){
                        return 'Jadwal Kelas';
                    },
                },
                {
                    extend: 'csvHtml5',
                    text: 'CSV',
                    className: 'btn bg-gray-dark',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    title: function(){
                        return 'Jadwal Kelas';
                    },
                    filename: function(){
                        return 'Jadwal Kelas';
                    },
                },
            ],
        });

    }
}
</script>
<style>
    .badge {
        font-size: 100%;
    }
</style>